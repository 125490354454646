@import '~antd/dist/antd.less';
@import '~react-perfect-scrollbar/dist/css/styles.css';

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Medium.eot');
  src: local('SF Pro Display Medium'), local('SFProDisplay-Medium'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Medium.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Medium.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Heavy.eot');
  src: local('SF Pro Display Heavy'), local('SFProDisplay-Heavy'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Heavy.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Heavy.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Heavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic.eot');
  src: local('SF Pro Display UltralightItalic'), local('SFProDisplay-UltralightItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-UltralightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.eot');
  src: local('SF Pro Display Regular Italic'), local('SFProDisplay-RegularItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-RegularItalic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Thin.eot');
  src: local('SF Pro Display Thin'), local('SFProDisplay-Thin'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Thin.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Thin.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Thin.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Light.eot');
  src: local('SF Pro Display Light'), local('SFProDisplay-Light'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Light.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Light.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Light.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Light.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-BlackItalic.eot');
  src: local('SF Pro Display Black Italic'), local('SFProDisplay-BlackItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BlackItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BlackItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Bold.eot');
  src: local('SF Pro Display Bold'), local('SFProDisplay-Bold'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Bold.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Bold.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Black.eot');
  src: local('SF Pro Display Black'), local('SFProDisplay-Black'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Black.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Black.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Black.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic.eot');
  src: local('SF Pro Display SemiboldItalic'), local('SFProDisplay-SemiboldItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-SemiboldItalic.ttf') format('truetype');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Ultralight.eot');
  src: local('SF Pro Display Ultralight'), local('SFProDisplay-Ultralight'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Ultralight.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Ultralight.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Ultralight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-LightItalic.eot');
  src: local('SF Pro Display LightItalic'), local('SFProDisplay-LightItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-LightItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-LightItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-LightItalic.ttf') format('truetype');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-ThinItalic.eot');
  src: local('SF Pro Display ThinItalic'), local('SFProDisplay-ThinItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-ThinItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-ThinItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-ThinItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-MediumItalic.eot');
  src: local('SF Pro Display MediumItalic'), local('SFProDisplay-MediumItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-MediumItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-MediumItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Semibold.eot');
  src: local('SF Pro Display Semibold'), local('SFProDisplay-Semibold'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Semibold.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Semibold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic.eot');
  src: local('SF Pro Display HeavyItalic'), local('SFProDisplay-HeavyItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-HeavyItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-Regular.eot');
  src: local('SF Pro Display Regular'), local('SFProDisplay-Regular'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Regular.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Regular.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro Display';
  src: url('./assets/fonts/SFProDisplay/SFProDisplay-BoldItalic.eot');
  src: local('SF Pro Display BoldItalic'), local('SFProDisplay-BoldItalic'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BoldItalic.woff2') format('woff2'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BoldItalic.woff') format('woff'),
  url('./assets/fonts/SFProDisplay/SFProDisplay-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}


body {
  overflow: hidden;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.ant-page-header {
  padding: 0;
  margin-bottom: 12px;
}

#root {
  height: 100%;
  overflow: hidden;
}

.base-layout, .simple-layout {
  height: 100vh;
  background-color: #F6F6F6;
}

.base-layout-sider, .simple-layout-sider {
  height: 100vh;
}

.base-layout-logo-container, .simple-layout-logo-container {
  padding: 20px;
}

.base-layout-logo, .simple-layout-logo {
  width: 100%;
  height: 26px;
  vertical-align: middle;
}

.base-layout-logo-elma-bot, .simple-layout-logo-elma-bot {
  width: 100%;
  height: 26px;
  vertical-align: middle;
}

.base-layout-sider .ant-menu, .simple-layout-sider .ant-menu {
  overflow: auto;
  height: calc(100% - 64px);
}

.base-layout-header, .simple-layout-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  position: relative;
  background: #fff;
  padding: 0 16px;
}

.app-info {
  font-size: 12px;
  max-width: 300px;
  line-height: 1;
  margin-left: auto;
  margin-right: 10px;
}

.base-layout-main, .simple-layout-main {
  height: 100%;
  overflow: hidden;
}

.base-layout-scrollable, .simple-layout-scrollable {
  height: calc(100% - 64px);
  overflow: auto;
}

.base-layout-scrollable-content, .simple-layout-scrollable-content {
  min-height: calc(100% - 64px);
  position: relative;
}

.base-layout-content, .simple-layout-content {
  flex: none;
  margin: 24px 16px;
  padding: 24px;
  background: #fff;
  min-height: 280px;
}

.base-layout-footer, .simple-layout-footer {
  margin-top: auto;
  text-align: center;
}

/* Progress Bar  */
.campaign-status {
  display: flex;
  flex-direction: column;
  align-items: center;

  .ant-progress-outer {
    padding-right: 0;
    margin-right: 0;
  }

  .ant-progress-text[title] {
    margin-left: 0;
    width: unset;
    line-height: unset;
  }
}

/* Validated table */
.ant-form-item-has-error .ant-table-wrapper  {
  border: 1px solid @error-color;
}

.ant-btn-primary:hover {
  background: #367EB2;
}

.ant-btn-primary:active {
  background: #0D4A75;
}

.ant-form-item-label > label.ant-form-item-required::before {
  color: @error-color;
}

.w-100 {
  width: 100%;
}

.justify-right {
  display: flex;
  justify-content: flex-end;
}

/* Таблицы */
.ant-table-tbody > tr.ant-table-row-selected > td {
  background-color: #EDEBE9;
}

.disabled-table {
  pointer-events: none;
  opacity: 0.7;
}

.ant-table-row {
  cursor: default;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background-color: #EDEBE9;
}

.ant-spin.web-chat-spinner, .flex-center {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Веб-чат */
.webchat {
  &__basic-transcript__focus-indicator {
    display: none;
  }

  &__bubble__content {
    h1 {
      font-size: 2em;
    }
    h2 {
      font-size: 1.5em;
    }
    h3 {
      font-size: 1.17em;
    }
    h4 {
      font-size: 1em;
    }
    h5 {
      font-size: 0.83em;
    }
    h6 {
      font-size: 0.67em;
    }
  }
}

blockquote {
  border-left: 4px solid #DADADA;
  color: #8C8C8C;
  margin: 8px 0;
  padding: 4px 8px;

  p {
    margin: 0;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;