@import "src/simple-bot/styles/variables";

.sb-bot-list {

  &__videos {

    &__wrapper {

      .ant-row {
        @media screen and (max-width: 1439px) {
          justify-content: space-evenly;
        }
      }
    }

    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;

      & > .sb-button-tertiary {

        .sb-icon {
          margin-right: 0;
        }
      }
    }

    &__title {
      font-family: @sb-font-family;
      font-weight: @sb-font-weight-bold;
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      color: @sb-font-color-base;
    }

    &__wrapper {
      max-height: 150px;
      overflow: hidden;
      margin-bottom: 15px;

      &_open {
        max-height: inherit;
      }

      .ant-row > .ant-col {
        flex: 0 0 auto !important;
      }
    }

    &__text {
      font-family: @sb-font-family;
      font-weight: @sb-font-weight-bold;
      font-size: @sb-font-size-small;
      line-height: @sb-line-height-small;
      color: @sb-primary-color;
      margin-right: 6px;
    }
  }

  .ant-page-header-heading-title {
    font-size: @sb-font-size-title-medium;
    line-height: @sb-line-height-title-medium;
    color: @sb-font-color-base;
    margin-right: 24px;
  }

  //NOTE: Работает только для разрешения 1440px, для других разрешений нужно добавить медиа запрос
  &__arrow {
    width: 15vw;
    position: absolute;
    top: -185px;
    left: 335px;
  }

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    @media screen and (max-width: 1023px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
  }

  &__wrapper {
    display: flex;
    position: relative;

    @media screen and (max-width: 576px) {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }

    .ant-page-header {
      margin-bottom: 0;
    }

    .ant-page-header-heading-left {
      margin: 0;
    }

    & > .sb-button {
      font-size: @sb-font-size-medium;
      height: 40px;
    }
  }

  &__cards-container {

    .ant-row > .ant-col {
      flex: 0 0 auto !important;
      & > .ant-list-item {
        margin-bottom: 0;
      }
    }

    .ant-row {
      display: flex;
      flex-wrap: wrap;
      margin-right: -16px;
    }
  }

  &__sort-panel {
    font-family: @sb-font-family;
    font-size: @sb-font-size-base;
    line-height: @sb-line-height-base;
    color: @sb-font-color-base;

    &__text {
      margin-right: 5px;
    }

    .ant-divider-vertical {
      height: 28px;
      background: @sb-divider-color-secondary;
    }

    .ant-radio-button-wrapper {
      border: none !important;
      padding: 0;
      background-color: transparent !important;
      font-family: @sb-font-family;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      color: @sb-font-color-base;

      &:hover {
        color: @sb-primary-color;
      }

      &:not(:first-child)::before {
        background-color: transparent !important;
      }
    }

    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none !important;
    }

    .ant-radio-button-wrapper-checked {
      color: @sb-primary-color;
      font-size: @sb-font-size-base;
      line-height: @sb-line-height-base;
      text-shadow: 0 0 .5px @sb-primary-color, 0 0 .5px @sb-primary-color;

      &::after {
        content: '';
        position: absolute;
        background: @sb-primary-color;
        left: 0;
        bottom: 5px;
        width: 100%;
        height: 2px;
      }
    }
  }

  &__empty {
    position: relative;
    margin-top: 170px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    .sb-bot-list-card {
      transform: rotate(-15deg);
      width: 440px;
      opacity: 0.7;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 130px;
    }

    &-title {
      font-weight: @sb-font-weight-bold;
      font-size: @sb-font-size-xx-large;
      line-height: @sb-line-height-xx-large;
      text-align: center;
    }

    &-text {
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      text-align: center;
      color: @sb-font-color-secondary;
    }
  }
}


@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;