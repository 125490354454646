@import "src/simple-bot/styles/variables";

@sb-avatar-color: #9254DE;

.sb-user-profile {
  line-height: @sb-line-height-base;
  cursor: pointer;
  display: flex;
  align-items: center;

  .ant-avatar {
    background-color: @sb-avatar-color;
    font-size: @sb-font-size-base;
    font-weight: @sb-font-weight-bold;
    line-height: @sb-line-height-base;
    width: 40px;
    height: 40px;

    .ant-avatar-string {
      margin-top: 9px;
    }
  }

  .sb-icon {
    color: @sb-icon-color-base;
    margin-left: 8px;
  }

  &:hover {
    .ant-avatar {
      background-color: darken(@sb-avatar-color, 20%);
    }

    .sb-icon {
      color: @sb-icon-color-hover;
    }
  }
}

.sb-user-profile-menu {
  .ant-dropdown-menu {
    padding-top: 12px;
    padding-bottom: 12px;

    .ant-dropdown-menu-item {
      color: @sb-context-menu-item-color;
      font-size: @sb-font-size-x-large;
      line-height: @sb-line-height-x-large;
      padding-left: 24px;
      padding-right: 24px;
      height: 44px;
      display: flex;

      * {
        display: inline;
        vertical-align: text-bottom;
        margin-right: 8px;
      }
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;