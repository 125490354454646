@import "src/simple-bot/styles/variables";
@import "src/simple-bot/styles/mixins";

@sb-selected-dialog-color: #F8F8F8;
@sb-user-message-background-color: #F5F5F5;
@sb-selected-message-color: #D9F7BE;
@sb-selected-message-border-color: #86A56A;
@sb-score-bar-min-color: #096DD9;
@sb-score-bar-max-color: #1CBF4A;

.sb-dialogs-card {
  font-size: @sb-font-size-base;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  color: @sb-font-color-base;

  &__header {
    background: @sb-control-color-background;
    box-shadow: @sb-border-bottom-box-shadow;
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 11;

    &__left {
      display: inline-flex;
      align-items: center;
      margin: 8px 0;
      overflow: hidden;

      .sb-button {
        margin-right: 10px;
      }

      &__title {
        display: flex;
        flex-direction: column;
        max-width: 100%;

        h3 {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-x-large;
          margin: 0;

          .sb-tag {
            margin-left: 8px;
          }
        }

        h4 {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-base;
          margin: 0 !important;
        }
      }
    }
  }

  @media screen and (min-width: 769px) {
    &__header {
      flex-wrap: nowrap;
    }
  }

  &__content {
    display: flex;
    background: @sb-content-page-background-color;
    flex-grow: 1;
    padding: 20px 36px;
    gap: 20px;
    overflow: auto;

    &__list-container {
      display: flex;
      flex-direction: column;
      background: @sb-control-color-background;
      border: @sb-border-default;
      border-radius: @sb-border-radius-large;
      width: 25%;

      &__filter {
        padding: 24px 24px 16px 24px;

        .sb-input {

          .ant-input-suffix::before {
            transition: all 0.3s;
            position: absolute;
            content: '';
            background: @sb-input-color-border;
            width: 1px;
            height: 100%;
            right: 44px;
            pointer-events: none;
          }

          &:hover, &:focus-within {
            .ant-input-suffix::before {
              background: @sb-input-color-hover;
            }
          }

          .ant-input-suffix {
            cursor: pointer;
            transition: all 0.3s;

            &:hover {
              color: @sb-input-color-hover;
            }

            .sb-icon.badge::after {
              position: absolute;
              content: '';
              background: @sb-primary-color;
              border-radius: 50%;
              width: 10px;
              height: 10px;
              transform: translate(-100%, 0);
            }
          }
        }
      }

      &__filter-menu {
        width: 400px;

        .ant-popover-arrow {
          display: none;
        }

        .ant-popover-inner {
          border: @sb-border-default;
          border-radius: @sb-border-radius-small;
          box-shadow: @sb-box-shadow-default;
        }

        .ant-popover-inner-content {
          padding: 20px;

          h2 {
            font-size: @sb-font-size-x-large;
            line-height: @sb-line-height-x-large;
            font-weight: @sb-font-weight-bold;
            color: @sb-font-color-base;
            margin-bottom: 10px;
          }

          h3 {
            font-size: @sb-font-size-base;
            line-height: @sb-line-height-base;
            font-weight: @sb-font-weight-bold;
            color: @sb-font-color-base;
            margin-bottom: 12px;
          }

          .sb-select {
            margin-bottom: 24px;
          }

          .sb-range-picker {
            margin-bottom: 24px;
            height: 50px;
          }
        }

        &__buttons {
          display: inline-flex;

          .sb-button:first-child {
            margin-right: 12px;
          }
        }
      }

      &__list {
        border-bottom-left-radius: @sb-border-radius-large;
        border-bottom-right-radius: @sb-border-radius-large;
        overflow: auto;
        height: 100%;

        .ant-table-cell {
          border-bottom: 0;
        }

        .ant-table-selection-column {
          display: none;
        }

        tr.ant-table-row-selected > td {
          background: @sb-selected-dialog-color !important;
        }

        .ant-table-row:hover td {
          background: @sb-selected-dialog-color !important;
        }

        .sb-scroll {
          padding: unset;
        }

        .ps__rail-y {
          margin-right: 8px;
        }

        .ant-skeleton {
          padding: 28px 24px;
        }

        &__item {
          display: flex;
          word-break: break-word;
          align-items: center;
          padding: 12px 8px;
          gap: 16px;
          cursor: pointer;
          width: 100%;

          .ant-badge .ant-avatar {
            background: @sb-control-color-background;
          }

          &__text {
            display: flex;
            font-size: @sb-font-size-base;
            line-height: @sb-line-height-base;
            justify-content: space-between;
            flex-direction: column;
            height: 69px;
            width: 100%;
            overflow: hidden;

            p {
              margin: 0;
            }

            &_main {
              display: flex;
              justify-content: space-between;
              font-weight: @sb-font-weight-bold;

              @media screen and (max-width: 1280px) {
                .sb-tag {
                  font-size: @sb-font-size-x-small;
                  width: 25%;
                  justify-content: center;
                }
              }
            }
          }
        }
      }
    }

    &__panel-container {
      display: flex;
      background: @sb-control-color-background;
      border: @sb-border-default;
      border-radius: @sb-border-radius-large;
      justify-content: center;
      width: 75%;

      &__messages {
        flex: auto;
        overflow: auto;
        width: 70%;
        padding: 0 8px;
        border-bottom-left-radius: @sb-border-radius-large;
        border-top-left-radius: @sb-border-radius-large;

        .sb-scroll {
          padding: 16px;
        }

        .infinite-scroll-component {
          overflow: hidden !important;
        }

        &__session-message {
          display: flex;
          justify-content: center;

          &__text {
            border: @sb-border-default;
            border-radius: 70px;
            padding: 0 12px;
            margin: 16px;
            font-size: @sb-font-size-x-small;
            line-height: @sb-line-height-x-small;
            color: @sb-font-color-secondary-2;

            &_closed {
              background-color: @sb-user-message-background-color;
            }
          }
        }

        &__user-message-container {
          display: flex;
          max-width: 80%;
          margin: 8px 0;

          &__message {
            border: @sb-border-default;
            border-radius: @sb-border-radius-large;
            background-color: @sb-user-message-background-color;
            cursor: pointer;
            padding: 8px 16px;

            &_selected {
              border-color: @sb-selected-message-border-color;
              background-color: @sb-selected-message-color;
            }

            &__text {
              word-break: break-word;
              font-size: @sb-font-size-base;
              line-height: @sb-line-height-base;

              p {
                margin: 0;
              }
            }

            &__time {
              color: @sb-font-color-secondary-2;
              font-size: @sb-font-size-x-small;
            }
          }
        }

        &__bot-message-container {
          display: flex;
          justify-content: flex-end;
          margin: 8px 0;

          &__message {
            border: @sb-border-default;
            border-radius: @sb-border-radius-large;
            background-color: @sb-primary-color;
            padding: 8px 16px;
            color: @sb-control-color-background;
            max-width: 80%;
            cursor: pointer;

            &_selected {
              border-color: @sb-selected-message-border-color;
              background-color: @sb-selected-message-color;
              color: @sb-control-color-foreground;
            }

            &__text {
              word-break: break-word;
              font-size: @sb-font-size-base;
              line-height: @sb-line-height-base;

              p {
                margin: 0;
              }
            }

            &__time {
              display: flex;
              justify-content: flex-end;
              opacity: 40%;
              font-size: @sb-font-size-x-small;
            }
          }
        }

        &__event {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-secondary-2;

          button {
            padding: 0;
            height: auto;
            font-size: @sb-font-size-x-small;
            line-height: @sb-line-height-x-small;
            font-weight: @sb-font-weight-normal;
          }

          &_end {
            justify-content: flex-end;
          }

          &_success {
            color: @sb-success-color;
          }

          &_error {
            color: @sb-error-color;
          }
        }

        &__buttons-title {
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-secondary-2;
          margin: 8px 0;
        }

        &__buttons-container {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: 8px;

          &__item {
            background-color: @sb-control-color-background;
            border-radius: @sb-border-radius-large;
            border: @sb-border-default;
            padding: 0 12px;

            &_selected {
              background-color: @sb-cell-color-selected;
            }

            &__text {
              line-height: @sb-line-height-x-large;
              font-weight: @sb-font-weight-normal;
              font-size: @sb-font-size-small;
            }
          }

          button {
            padding: 0;
            line-height: @sb-line-height-x-large;
            font-weight: @sb-font-weight-normal;
            font-size: @sb-font-size-small;
          }
        }

        &__attachments-title {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: @sb-font-size-x-small;
          line-height: @sb-line-height-x-small;
          font-weight: @sb-font-weight-normal;
          color: @sb-font-color-secondary-2;
          margin: 8px 0;

          &_end {
            justify-content: flex-end;
          }
        }

        &__attachments-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;

          &_end {
            justify-content: flex-end;
          }

          &__item {
            background-color: @sb-control-color-background;
            border-radius: @sb-border-radius-large;
            border: @sb-border-default;
            padding: 2px 12px;

            &__text {
              line-height: @sb-line-height-x-large;
              font-weight: @sb-font-weight-normal;
              font-size: @sb-font-size-small;
            }
          }

          a {
            display: flex;
            align-items: center;
            gap: 4px;
            font-size: @sb-font-size-small;
            font-weight: @sb-font-weight-normal;
            line-height: @sb-line-height-base;
          }
        }
      }

      &__info {
        width: 30%;
        border-left-color: #D9D9D9;
        border-left-width: 1px;
        border-left-style: solid;

        h4 {
          margin-bottom: 32px;
          font-size: @sb-font-size-x-large;

          @media screen and (max-width: 1280px) {
            font-size: @sb-font-size-base;
          }
        }

        button {
          justify-content: center;
          width: 100%;
          margin-top: 16px;
        }

        .ant-avatar {
          flex-shrink: 0;
        }

        .sb-panel {
          border-radius: @sb-border-radius-x-small;
        }

        .scrollbar-container:not(#sb-dialog-message-info-scroll) {
          height: unset !important;
        }

        > .scrollbar-container {
          padding: 20px 32px;
        }

        .ps__rail-y {
          margin-right: 8px;
        }

        &__text {
          word-break: break-word;
          font-size: @sb-font-size-base;
          line-height: @sb-line-height-base;
          font-weight: @sb-font-weight-bold;
          color: @sb-font-color-base;
          margin-top: 16px;
          margin-bottom: 4px;

          @media screen and (max-width: 1280px) {
            font-size: @sb-font-size-small;
          }
        }

        &__secondary-info {
          font-size: @sb-font-size-small;
          line-height: @sb-line-height-small;
          color: @sb-font-color-secondary;
        }

        &__additional-title {
          font-size: @sb-font-size-x-large;
          line-height: @sb-line-height-base;
          display: flex;
          align-items: center;
          margin-top: 32px;
          margin-bottom: 8px;

          @media screen and (max-width: 1280px) {
            font-size: @sb-font-size-medium;
          }

          .sb-icon {
            margin-right: 8px;
          }
        }

        &__description {
          font-size: @sb-font-size-small;
          line-height: @sb-line-height-x-large;
          margin-bottom: 8px;
        }

        &__border-frame {
          border: @sb-border-default;
          border-radius: @sb-border-radius-large;
          padding: 20px 16px;
          background-color: @sb-user-message-background-color;

          &__text {
            font-size: @sb-font-size-base;
            font-weight: @sb-font-weight-bold;
            line-height: @sb-line-height-base;
          }
        }

        &__buttons-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-bottom: 8px;

          &__button {
            border: @sb-border-default;
            border-radius: 29px;
            padding: 8px 18px;
            background-color: @sb-control-color-background;

            &__text {
              font-size: @sb-font-size-small;
              line-height: @sb-line-height-x-large;
            }
          }
        }

        &__attachments-container {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          margin-bottom: 8px;

          &__attachment {
            border: @sb-border-default;
            border-radius: 29px;
            padding: 8px 18px;
            background-color: @sb-control-color-background;

            a {
              display: flex;
              align-items: center;
              gap: 4px;
              font-size: @sb-font-size-small;
              font-weight: @sb-font-weight-normal;
              line-height: @sb-line-height-base;
            }
          }
        }

        &__intent-frame {
          border: @sb-border-default;
          border-radius: @sb-border-radius-xxx-small;
          padding: 16px;
          margin-bottom: 8px;
          background-color: @sb-control-color-background;

          &__item {
            display: flex;
            justify-content: space-between;

            &__name {
              font-size: @sb-font-size-small;
              line-height: @sb-line-height-x-large;
              font-weight: @sb-font-weight-bold;
              color: @sb-font-color-secondary;
            }

            &__score {
              font-size: @sb-font-size-small;
              line-height: @sb-line-height-x-large;
              color: @sb-primary-color;
              align-self: center;
            }
          }

          &__description {
            font-size: @sb-font-size-small;
            line-height: @sb-line-height-small;
            color: @sb-font-color-base;
          }

          &__divider {
            background: linear-gradient(90deg, @sb-score-bar-min-color, @sb-score-bar-max-color);
            height: 2px;
            margin: 16px 0;
          }
        }

        &__error-details {
          word-break: break-word;
          font-size: @sb-font-size-x-small;
        }

        &__log-details {
          font-size: @sb-font-size-x-small;

          pre {
            font-size: @sb-font-size-xx-small;
            white-space: pre-wrap;
          }
        }
      }

      &_empty {
        text-align: center;
        align-self: center;

        .sb-icon {
          color: @sb-font-color-base;
          opacity: 0.2;
        }

        div {
          margin-top: 16px;
          color: @sb-font-color-secondary;
        }
      }
    }
  }

  .sb-spin {
    .center-position;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;