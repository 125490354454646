@import "src/simple-bot/styles/variables";

@sb-modal-small-vertical-padding: 24px;
@sb-modal-large-vertical-padding: 4vh;

.ant-modal-wrap {
  display: flex;
}

.sb-modal {
  padding-bottom: 0 !important;

  .ant-modal-content {
    max-height: calc(100vh - 2 * @sb-modal-small-vertical-padding);
    display: flex;
    flex-direction: column;

    .ant-modal-body {
      overflow: hidden;
      display: flex;
      flex-direction: column;
    }
  }
}

.sb-modal.sb-modal_small {
  top: unset;
  align-self: center;
  color: @sb-font-color-base;

  & > .ant-modal-content {
    padding: 24px;
    background: @sb-control-color-background;
    box-shadow: @sb-box-shadow-default;
    border-radius: @sb-border-radius-small;

    & > .ant-modal-header {
      padding: 0;
      border: none;

      & > .ant-modal-title {
        font-style: normal;
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-xx-large;
        line-height: @sb-line-height-xx-large;
        display: flex;
        align-items: center;
        color: @sb-control-color-foreground;
        margin: 0 42px 0 0;
      }
    }

    & > .ant-modal-close {
      margin: 15px 10px 0 0;
    }

    & > .ant-modal-body {
      margin: 20px 0;
      padding: 0;
      display: flex;
      flex-direction: column;
    }

    & > .ant-modal-footer {
      display: flex;
      padding: 0;
      border: none;
      text-align: left;

      & > .sb-button + .sb-button {
        margin-left: 12px;
      }
    }

    .sb-modal__header {
      margin-bottom: 20px;
    }

    .sb-modal__row-stretch {
      flex: 1 1 auto;
      min-height: 0;

      & > .ant-col {
        display: flex;
        flex-direction: column;
      }
    }

    .sb-modal__col-main {
      padding-bottom: 0 !important;

      .ant-col {
        margin-bottom: 10px;
      }

      .sb-scroll {
        .scroll-content {
          height: unset !important;
        }

        > *:first-child {
          height: unset !important;
        }
      }
    }

    .sb-modal__subtitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 42px;
      margin: 21px 0 0 0;

      h3 {
        font-size: @sb-font-size-base;
        line-height: @sb-line-height-base;
        color: @sb-control-color-foreground;
        font-weight: @sb-font-weight-bold;
        margin-bottom: 0;
      }

      &-actions {
        display: flex;

        .sb-button:not(:last-child) {
          margin-right: 12px;
        }
      }
    }
  }
}

.sb-modal.sb-modal_large {
  color: @sb-font-color-base;
  top: @sb-modal-large-vertical-padding;
  height: calc(100vh - 2 * @sb-modal-large-vertical-padding);
  padding-bottom: 0;

  .ant-modal-header {
    border-top-left-radius: @sb-border-radius-large;
    border-top-right-radius: @sb-border-radius-large;
    background-color: @sb-default-background-color;
  }

  .ant-modal-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    border-radius: @sb-border-radius-large;
    background-color: @sb-default-background-color;
    padding: 40px 0px 0px 0px;

    & > .ant-modal-close {
      margin: 5px 0 0 0;
    }
  }

  .ant-modal-body {
    min-height: 0;
    padding: 40px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    flex-grow: 1;
  }

  .ant-modal-footer {
    padding: 0 40px;
    border-width: 0;
    width: 100%;
  }
}


@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;