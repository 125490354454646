@import "src/simple-bot/styles/variables";

.sb-add-scenario-modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;

  .ant-spin {
    width: 100%;
  }

  .sb-select {
    margin: 0 0 20px 0;
  }

  .ant-row-no-wrap {
    flex-flow: row;
  }

  &__main {
    margin: -20px -20px 20px;
    min-height: 0;
    flex-grow: 1;

    &__column {
      display: flex;
      flex-direction: column;
      padding: 20px;

      .ant-list {
        margin-top: 0;

        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
          }
        }
      }

      .sb-scroll {
        .scroll-content {
          height: unset;
        }
      }

      .sb-search-row {
        margin-top: 16px;
        margin-left: 8px;
        height: 51px;
        flex-shrink: 0;
      }

      .sb-scenario-templates-list-container {
        flex-flow: column;

        .sb-scroll.ps--active-y {
          padding-right: 0;
        }

        .scrollbar-container {
          padding-left: 8px;
          margin-top: 38px;
          padding-top: 2px;
          margin-right: -20px;
          min-width: 100%;
        }

        justify-content: stretch;

        .sb-scenario-templates-list {
          flex-grow: 1;
          height: unset;

          .ant-row .ant-col {
            flex: 0 0 auto !important;

            .ant-list-item {
              margin-right: 20px;
              margin-bottom: 20px;
            }
          }

          .ant-spin-container::after {
            background: transparent;
          }
        }
      }

      &__footer {
        margin-top: 20px;
        flex-shrink: 0;
      }
    }

    .sb-modal-menu-container {
      display: flex;
      flex-direction: column;
      max-width: 372px;

      h3 {
        font-size: @sb-font-size-title-medium;
        font-weight: @sb-font-weight-bold;
        line-height: @sb-line-height-title-medium;
        margin-bottom: 12px;
      }

      h4 {
        margin-top: 28px;
        margin-bottom: 20px;
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-xx-large;
        line-height: @sb-line-height-xx-large;
      }

      .sb-modal-scrollable-area {
        overflow-y: auto;
        padding-right: 2px;
      }

      .scrollbar-container {
        margin-top: 0;
      }

      .sb-select {
        margin-bottom: 21px;
      }

      .ant-menu {
        background-color: transparent !important;
        border-width: 0;

        .ant-menu-item {
          margin-top: 0;
          margin-bottom: 4px;
          font-size: 24px !important;
          line-height: 32px;
        }

        .ant-menu-item-selected {
          color: @sb-primary-color;
          background-color: transparent !important;
        }

        .ant-menu-item-active {
          background-color: transparent !important;
        }
      }

      .ant-menu-vertical .ant-menu-item {
        padding-left: 0;
      }
    }
  }
}

.initial-loading-spin {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -13px;
  margin-left: -10px;
  transform: scale(2);
}

.creating-scenario {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-grow: 1;

  &__spin {
    transform: scale(2);
    transform-origin: 12px 0;
    height: 52px;
  }
}

.empty-results,
.creating-scenario {
  text-align: center;

  h4 {
    color: @sb-font-color-base;
    font-weight: @sb-font-weight-bold;
    font-size: @sb-font-size-xx-large;
    line-height: @sb-line-height-xx-large;
  }

  p {
    color: @sb-font-color-secondary;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;