@import "src/simple-bot/styles/variables";

.sb-button {
  font-weight: 500;
  font-family: @sb-font-family;
  display: flex;
  align-items: center;

  &:after {
    display: none;
  }

  &_primary,
  &_primary:focus,
  &_icon-primary,
  &_icon-primary:focus {
    color: @sb-control-color-background !important;
    background: @sb-primary-color !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      color: @sb-control-color-background;
      background: @sb-primary-color;
      border: none;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-control-color-background;
      background: @sb-button-color-active;
      border: none;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-control-color-background !important;
      background: @sb-button-bg-color-disabled !important;
      border: none;
      box-shadow: none;
    }
  }

  &_icon-primary-filled-48,
  &_icon-primary-filled-48:focus {
    color: @sb-control-color-background !important;
    background: @sb-primary-color !important;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      color: @sb-control-color-background;
      background: @sb-primary-color;
      border: none;
      box-shadow: @sb-button-box-shadow !important;
    }

    &:active {
      color: @sb-control-color-background;
      background: @sb-button-color-active !important;
      border: none;
      box-shadow: none;
    }
  }

  &_secondary,
  &_secondary:focus,
  &_icon-secondary,
  &_icon-secondary:focus {
    color: @sb-primary-color;
    background: transparent;
    border: 1px solid @sb-primary-color;
    box-shadow: none;

    &:hover {
      color: @sb-primary-color;
      background: transparent;
      border: 1px solid @sb-primary-color;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-button-color-active;
      background: transparent;
      border: 1px solid @sb-button-color-active;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled !important;
      background: @sb-control-color-background;
      border: 1px solid @sb-button-color-disabled !important;
      box-shadow: none;
    }
  }

  &_tertiary&_big {
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
  }

  &_primary&_big, &_secondary&_big, &_icon-primary&_big, &_icon-secondary&_big {
    padding: 0 16px;
    border-radius: @sb-border-radius-small;
    height: 48px;
    font-size: @sb-font-size-x-large;
    line-height: @sb-line-height-x-large;
  }

  &_tertiary&_medium {
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &_primary&_medium, &_secondary&_medium, &_icon-primary&_medium, &_icon-secondary&_medium {
    padding: 0 12px;
    border-radius: @sb-border-radius-x-small;
    height: 36px;
    font-size: @sb-font-size-small;
    line-height: @sb-line-height-small;
  }

  &_icon-primary-48,
  &_icon-primary-48:focus,
  &_icon-primary-filled-48,
  &_icon-primary-filled-48:focus,
  &_icon-secondary-48,
  &_icon-secondary-48:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 48px;
    border-radius: @sb-border-radius-small;
    box-shadow: none;
    color: @sb-primary-color;
    background: @sb-control-color-background;
  }

  &_icon-primary-48,
  &_icon-primary-filled-48 {
    border: 1px solid @sb-primary-color;

    &:hover {
      color: @sb-primary-color;
      background: @sb-control-color-background;
      border: 1px solid @sb-primary-color;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-button-color-active;
      background: @sb-control-color-background;
      border: 1px solid @sb-button-color-active;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled !important;
      background: @sb-control-color-background;
      border: 1px solid @sb-button-color-disabled !important;
      box-shadow: none;
    }
  }

  &_icon-secondary-48 {
    border: none;

    &:hover {
      color: @sb-primary-color;
      background: @sb-control-color-background;
      border: none;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-button-color-active;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled !important;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
    }
  }

  &_icon-primary-32,
  &_icon-primary-32:focus,
  &_icon-secondary-32,
  &_icon-secondary-32:focus {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    box-shadow: none;
    color: @sb-primary-color;
    border: none;
  }

  &_icon-primary-32 {
    background: @sb-control-color-background;

    &:hover {
      color: @sb-primary-color;
      background: @sb-control-color-background;
      border: none;
      box-shadow: @sb-button-box-shadow;
    }

    &:active {
      color: @sb-button-color-active;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-primary-color;
      background: @sb-control-color-background;
      border: none;
      box-shadow: none;
      opacity: 0.5;
    }
  }

  &_icon-secondary-32 {
    background: transparent;

    &:hover {
      color: @sb-primary-color;
      background: transparent;
      border: none;
      box-shadow: none;
    }

    &:focus {
      color: @sb-button-color-active;
      background: transparent;
      border: none;
      box-shadow: none;
    }

    &:active {
      color: @sb-button-color-active;
      background: transparent;
      border: none;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled !important;
      background: transparent;
      border: none;
      box-shadow: none;
    }
  }

  &_link {
    border: none;
    box-shadow: none;
    color: @sb-primary-color;
    background: transparent;

    &:hover, &:focus {
      color: @sb-button-color-active;
    }
  }

  &_tertiary, &_tertiary:focus {
    color: @sb-primary-color;
    background: transparent;
    border: none;
    border-bottom: 1px solid transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 0;

    .sb-icon {
      margin-right: 6px;
    }

    &:hover {
      color: @sb-primary-color;
      background: transparent;
      border: none;
      border-bottom: 1px solid @sb-primary-color;
      box-shadow: none;
    }

    &:active {
      color: @sb-button-color-active;
      background: transparent;
      border: none;
      border-bottom: 1px solid @sb-button-color-active;
      box-shadow: none;
    }

    &[disabled], &[disabled]:hover, &[disabled]:focus {
      color: @sb-button-color-disabled !important;
      background: transparent;
      border: none;
      border-bottom: 1px solid transparent;
      box-shadow: none;
    }
  }

  &_icon-primary, &_icon-secondary {
    display: flex;
    align-items: center;

    .sb-icon:first-child {
      margin: 0 10px 0 0;
    }

    .sb-icon:last-child {
      margin: 0 0 0 10px;
    }
  }

  &_text-only {
    font-size: @sb-font-size-x-large;
    font-weight: normal;
    line-height: @sb-line-height-x-large;
    color: @sb-font-color-base !important;
    background-color: @sb-button-color-alert !important;
    padding: 4px 12px;
    border: none;
    border-radius: 20px;

    &:hover {
      background-color: @sb-button-color-alert;
      color: @sb-primary-color !important;
    }
  }

  &_icon-only {
    width: auto;
    height: auto;
    border: none;
    padding: 0;
  }

  &_primary&_danger, &_primary&_danger:hover, &_primary&_danger:focus,
  &_icon-primary&_danger, &_icon-primary&_danger:hover, &_icon-primary&_danger:focus {
    background-color: @sb-button-color-danger;
  }

  &_primary&_danger:active,
  &_icon-primary&_danger:active {
    background-color: @sb-button-color-danger-active;
  }

  &_secondary&_danger, &_secondary&_danger:hover, &_secondary&_danger:focus,
  &_icon-secondary&_danger, &_icon-secondary&_danger:hover, &_icon-secondary&_danger:focus {
    color: @sb-button-color-danger;
    border: 1px solid @sb-button-color-danger;
  }

  &_secondary&_danger:active,
  &_icon-secondary&_danger:active {
    color: @sb-button-color-danger-active;
    border: 1px solid @sb-button-color-danger-active;
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;