@import "src/simple-bot/styles/variables";

.sb-webchat {
  background: @sb-control-color-background;
  border-radius: @sb-border-radius-small;
  box-shadow: @sb-box-shadow-default;
  padding-bottom: 16px;
  width: 370px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &_fullscreen {
    height: 100%;
    width: 100%;

    .sb-webchat__header__close-button,
    .sb-webchat__header__fold-button,
    .sb-webchat__header__expand-button {
      display: none;
    }
  }

  &_expanded {
    width: 50vw;
    height: 90vh!important;
  }

  &__header {
    box-shadow: @sb-border-bottom-box-shadow;
    padding: 8px 4px 8px 16px;

    &__title {
      min-width: 0;

      h1 {
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-x-large;
        line-height: @sb-line-height-x-large;
        color: @sb-font-color-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      p {
        font-weight: @sb-font-weight-bold;
        font-size: @sb-font-size-x-small;
        line-height: @sb-line-height-x-small;
        color: @sb-font-color-secondary-2;
      }

      h1,
      p {
        margin-bottom: 0;
      }
    }

    .sb-icon {
      color: @sb-webchat-accent-color;
    }
  }

  &__content {
    height: 100%;
    flex-grow: 1;
    background: @sb-control-color-background;
    overflow: hidden;

    .webchat__basic-transcript__transcript {
      padding-left: 6px;
      padding-right: 6px;

      .webchat__stacked-layout__avatar-gutter > div {
        border: 1px solid #F5F5F5 !important;
        box-sizing: content-box;
      }

      .webchat__bubble__content > div {
        padding: 0 !important;
      }

      .webchat__bubble__content {
        padding: 8px 12px;

        p {
          padding: 0;
          margin: 0;
        }
      }

      .webchat__stacked-layout--from-user {
        .webchat__fileContent__fileName {
          color: @sb-webchat-outbound-attachment-primary-font-color;
        }

        .webchat__fileContent__size {
          color: @sb-webchat-outbound-attachment-secondary-font-color;
          font-size: @sb-font-size-x-small;
        }

        .webchat__fileContent__downloadIcon {
          fill: @sb-webchat-outbound-attachment-primary-font-color;
          transform: scale(1.5);
        }
      }

      .webchat__basic-transcript__activity--from-bot {
        .webchat__fileContent__downloadIcon {
          transform: scale(1.5);
        }
      }
    }

    .webchat__suggested-action__button {
      border-radius: @sb-border-radius-large !important;

      &:hover {
        box-shadow: @sb-box-shadow-default !important;
      }
    }

    .webchat__send-box__main {
      border-radius: @sb-border-radius-small;
      margin: 0 16px;
      min-height: 48px !important;

      &:hover,
      &:focus-within {
        border-color: @sb-webchat-accent-color !important;
      }

      input::placeholder {
        color: @sb-font-color-secondary-2 !important;
      }
    }

    .webchat__send-box__button {
      background: @sb-webchat-accent-color !important;
      border-radius: 12px !important;
      margin: 4px;

      &:hover {
        box-shadow: @sb-button-box-shadow !important;
      }
    }

    .webchat__imageAvatar__image > img {
      width: 50%;
    }

    &__spinner {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .webchat__suggested-actions {
      .react-film__filmstrip {
        padding-left: 10px;

        &__list {
          flex-wrap: wrap;
          max-height: 200px;
          overflow-x: hidden;
          overflow-y: auto;

          &::-webkit-scrollbar {
            background-color: transparent;
            width: 12px;
          }

          &::-webkit-scrollbar-thumb {
            background-color: @sb-scroll-color-thumb;
            border-radius: @sb-border-radius-large;
            border: 2px solid transparent;
            background-clip: content-box;
          }
        }

        &__item {
          padding-left: 0 !important;
        }
      }
    }

    .webchat__basic-transcript__scrollable::-webkit-scrollbar {
      background-color: transparent;
      width: 12px;
    }

    .webchat__basic-transcript__scrollable::-webkit-scrollbar-thumb {
      background-color: @sb-scroll-color-thumb;
      border-radius: @sb-border-radius-large;
      border: 2px solid transparent;
      background-clip: content-box;
    }
  }
}

@primary-color: #1E6599;@layout-header-background: #062543;@success-color: #56B461;@warning-color: #FABE00;@error-color: #E62D31;@alert-error-bg-color: #FCEAEA;@alert-warning-bg-color: #FFF8E5;@alert-success-bg-color: #EEF7EF;@alert-text-color: #949494;@layout-body-background: #F6F6F6;@font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';@font-size-base: 15px;